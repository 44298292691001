.navbar {
  font-family: $defaultFontFamily;
  font-size: 1.25rem;

  &--small {
    img {
      height: 50px;
      width: auto;
    }
  }

  @apply mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8 font-light;

  &__menu {
    @apply hidden lg:flex lg:gap-x-10 items-center;

    &__item {
      @apply text-white uppercase font-light;
    }
  }

  &__toggler {
    @apply -m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white hover:text-white;
  }
}
