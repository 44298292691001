@tailwind base;
@tailwind components;
@tailwind utilities;

/* HTML5 Boilerplate header */
@import "vendor/h5bp_header";

@import "fonts/josefin";
@import "fonts/nasalization";

/* Custom project files */
@import "variables";
@import "mixins";
@import "helpers";
@import "custom";
@import "components";

/* HTML5 Boilerplate footer */
@import "vendor/h5bp_footer";
