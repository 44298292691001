/*! custom.css - Project style entry point */

/* ==========================================================================
   Author's custom styles - Follow BEM and Components CSS file used for helpers
   ========================================================================== */

html,
body {
    height: 100%;
    padding: 0;
    margin: 0;
    width: 100%;

  background: rgba(65,0,131,1);
  background: linear-gradient(-45deg, rgba(235,102,37,1) 0%, rgba(65,0,131,1) 100%);
}

body {
    background-color: $backColor;
    color: $defaultTextColor;
    font-family: $defaultFontFamily;
    font-size: 1.15em;
    font-weight: 300;
    padding-top: 110px;
}

body.header--small {
  padding-top: 80px;
}

body.overflow-hidden {
  overflow: hidden;
}

img,
video {
    width: 100%;
}

a:hover {
  cursor: pointer;
}

ul {
    padding: 0;
    list-style: none;
}

hr {
  border-top: 1px solid $emColor1;
}

*:focus {
  outline: none;
}


header {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 99;
  transition: background-color 0.5s ease;

  /* purgecss ignore */
  &.header--bg-1 {
    background-color: $emColor1;
    transition: background-color 0.5s ease;
    border-bottom: 3px solid;
    border-image: linear-gradient(to left, #EB6625, rgb(76,17,118)) 0 0 1 0;
  }
}

.responsive-image {
  height: auto;
  max-width: 100%;
}

/* Media query imports - Follows BS notation*/
@include sm {

}

@include md {

}

@include lg {

}

@include xl {

}
