.text {
  font-size: 1.10rem;
  @apply font-light;

  @include sm {
    font-size: 1.15rem;
  }

  &--small {
    font-size: 1rem;
  }

  strong {
    font-weight: 600;
  }
}
