.footer-contact {
  color: $emColor1;
  @apply grid grid-cols-1 md:grid-cols-2 gap-4;

  &__title {
    font-family: $emFontFamily;

    @apply uppercase text-3xl py-5;
  }

  &__address {
    @apply font-light not-italic py-1;
  }

  &__form {
    background-color: $emColor2;
    @apply rounded-3xl text-white py-12 px-12;

    &__notice {
      font-size: 0.75rem;

      @apply my-3 font-light;
    }

    &__policy {
      label {
        cursor: pointer;
      }

      a {
        text-decoration: underline;
      }
    }
  }
}
