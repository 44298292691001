$defaultPaddingX: 15px;
$defaultPaddingY: 30px;

$smallPaddingX: calc($defaultPaddingX / 2);
$mediumPaddingX: calc($defaultPaddingX * 2);
$largePaddingX: calc($defaultPaddingX * 3);
$xLargePaddingX: calc($defaultPaddingX * 4);

$xSmallPaddingY: calc($defaultPaddingY / 4);
$smallPaddingY: calc($defaultPaddingY / 2);
$mediumPaddingY: calc($defaultPaddingY * 2);
$largePaddingY: calc($defaultPaddingY * 3);
$xLargePaddingY: calc($defaultPaddingY * 4);
