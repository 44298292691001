.pagination {
  border-top: 1px solid $darkColor2;
  color: $darkColor2;
  padding-bottom: $defaultPaddingY;
  margin-top: $mediumMarginY;

  @apply flex justify-center;

  &__item {
    @apply px-4 pt-4 text-sm font-medium;

    &:hover {
      color: $emColor1;
    }

    &.active {
      color: $emColor2;
      cursor: unset;

      &:hover {
        color: $emColor2;
      }
    }
  }

}
