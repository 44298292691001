.button {
  // TODO : check font margin
  display: block;
  font-family: $defaultFontFamily;
  font-size: 1.25rem;
  letter-spacing: 2px;

  &--default-margin {
    margin-left: $defaultMarginX;
    margin-right: $defaultMarginX;
  }

  &:hover {
    @apply transition-colors;
  }

  @apply my-2 pt-1 cursor-pointer font-light w-fit px-8 rounded-full uppercase text-center;

  &--primary {
    color: $wColor;

    &:hover {
      background-color: white;
      color: $emColor2;
    }

    @apply text-white;
  }

  &--secondary {
    background-color: $emColor2;
    color: $wColor;
    border: 1px solid $emColor2;

    &:hover {
      background-color: $wColor;
      color: $emColor2;
    }
  }

  &--tertiary {
    background-color: $emColor1;
    color: $wColor;

    &:hover {
      background-color: white;
      border: 1px solid $emColor1;
      color: $emColor1;
    }

    @apply border border-white text-white;
  }

  &--forth {
    background-color: $wColor;
    border: 1px solid $emColor2;
    color: $emColor2;

    &:hover {
      background-color: $emColor2;
      border-color: $wColor;
      color: $wColor;
    }
  }

  &--w-border {
    @apply border border-white;
  }

  &--small {
    font-size: 1rem;
    letter-spacing: 0;
    @apply px-3;
  }

  &--no-uppercase {
    text-transform: none;
  }

}
