.icon {
  background-color: #410083;
  border-radius: 50%;
  height: 30px;
  padding-left: 7px;
  padding-right: 7px;
  width: 30px;

  @apply fill-white;
}
