$backColor: #410083;

$defaultTextColor: #000000;

// Black & White colors
$wColor: #ffffff;
$bColor: #000000;

// Theme colors
$emColor1: #410083;
$emColor2: #EB6625;
$emColor3: #efefef;
$emColor4: #e83e8c;

// Theme grays
$lightColor1: #EDEDED;
$lightColor2: #efefef;
$darkColor1: #111111;
$darkColor2: #808080;

$defaultButtonTextColor: #ffffff;
$defaultButtonBackColor: #EB6625;
$defaultButtonEmTextColor: #ffffff;
$defaultButtonEmBackColor: #410083;
