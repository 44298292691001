.section {
  @apply w-full;

  &__container {
    @apply mx-auto w-full px-4 py-14 sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-lg;

    &--grid-2 {
      @apply grid grid-cols-1 md:grid-cols-2 gap-10;
    }

    &--sm-grid-2 {
      @apply grid grid-cols-1 sm:grid-cols-2 gap-10;
    }

    &--small-padding {
      padding-bottom: $xSmallPaddingY;
      padding-top: $xSmallPaddingY;
    }

    &--default-padding {
      padding-bottom: $defaultPaddingY;
      padding-top: $xSmallPaddingY;
    }

    &--small-padding-top {
      padding-top: $xSmallPaddingY;
    }

    &--xl {
      @apply xl:max-w-screen-xl;
    }
  }

  &__title {
    font-family: $emFontFamily;
    font-size: 2rem;
    margin-bottom: $largeMarginY;
    @apply uppercase;

    &--no-margin {
      margin-bottom: 0;
    }
  }

  &__subtitle {
    font-size: 1.25rem;
    @apply uppercase;
  }

  &__body {
    font-size: 1.10rem;
    @apply font-light;

    @include sm {
      font-size: 1.15rem;
    }

    &--small {
      font-size: 1.05rem;
    }

    &--mb-large {
      margin-bottom: $largeMarginY;
    }
  }

  &__content {
    p {
      margin-bottom: $smallMarginY;
    }

    blockquote {
      display: flex;
      font-weight: 600;
      margin-top: $mediumMarginY;

      &:before {
        content: url("../img/quotes_orange.svg");
        height: auto;
        width: 50px;
      }
    }

    &--centered {
      @apply flex justify-center flex-col;
    }
  }

  &--bordered {
    border-top: 1px solid;
    border-image: linear-gradient(to right, #EB6625, #410083) 1 0 0 0;

    &--medium {
      border-top: 3px solid;
    }

    &--large {
      border-top: 15px solid;
    }
  }

  &--medium-padding-top {
    @apply pt-10;
  }

  &__image {
    &--neg-top {
      margin-top: -150px;

      @apply hidden md:block;
    }
  }

  &--relative {
    @apply relative
  }

  &--gradient {
    background: linear-gradient(-90deg, rgba(65, 0, 131, 1) 0%, rgba(235, 102, 37, 1) 100%);
  }

  &--generic {
    h1 {
      font-family: $emFontFamily;
      font-size: 2rem;
      margin-bottom: $largeMarginY;
      margin-top: $largeMarginY;
      @apply uppercase;
    }

    h2 {
      color: $emColor2;
      font-size: 1.25rem;
      margin-bottom: $mediumMarginY;
      margin-top: $mediumMarginY;
      @apply uppercase;
    }

    h1, h2 {
      text-align: center;
    }

    p {
      margin-bottom: $smallMarginY;
    }

    a {
      @apply underline;
    }

    ul {
      // default ul style
      list-style-type: disc;
      margin: 1em;
      padding-left: 40px;
    }
  }
}
