.home-services {
  &__item {
    display: none;
    margin-left: -400px;

    img {
      height: 100%;
      width: auto;
    }
  }

  &__item:nth-child(1),
  &__item:nth-child(2) {
    @include sm {
      display: inline-block;
    }
  }

  &__item:nth-child(1) {
    @include sm {
      animation: fall-and-bounce-first 2s ease-in-out forwards;
    }
  }

  &__item:nth-child(2) {
    @include sm {
      animation: fall-and-bounce 2s ease-in-out forwards;
    }
  }

  &__item:nth-child(3) {
    @include lg {
      animation: fall-and-bounce 2s ease-in-out forwards;
      display: inline-block;
    }
  }


  &__item:nth-child(1) {
    margin-bottom: 10px;
    width: 12%;
  }

  &__item:nth-child(2) {
    margin-bottom: 70px;
    width: 12%;
    margin-top: -30px;
  }

  &__item:nth-child(3) {
    margin-bottom: 30px;
    width: 11%;
  }


  &__link {
    padding-bottom: $smallPaddingY;
    padding-top: $smallPaddingY;
    display: flex;
    justify-content: center;

    @include sm {
      padding-bottom: 0;
      padding-top: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 40%;
    }

    @include md {
      left: 51%;
    }
  }

}

@keyframes fall-and-bounce-first {
  0% {
    opacity: 1;
  }
  100% {
    margin-left: 5%;
  }
}

@keyframes fall-and-bounce {
  0% {
    opacity: 1;
  }
  100% {
    margin-left: 3%;
  }
}
