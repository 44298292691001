.footer-navigation {
  color: $emColor1;
  display: flex;
  justify-content: space-between;
  @apply bg-white py-8;

  &__item {
    font-size: 0.75rem;
    @apply uppercase pr-1;

    &:not(:last-child):after {
      content: ' - ';
    }
  }

  &__icon {
    display: inline-block;
    margin-left: $smallMarginX;
  }
}
