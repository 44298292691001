.header {
  margin-bottom: 50px;
  margin-top: 50px;

  @apply flex justify-center;

  &--constraint {
    @apply mx-auto w-full sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-lg;
  }

  &--xxl {
    margin-bottom: 0;
    text-align: center;
  }

  &__title {
    color: $wColor;
    font-family: $emFontFamily;
    font-size: 2rem;
    text-align: center;
    line-height: normal;

    &--xl {
      font-family: $defaultFontFamily;

      @apply font-semibold;
    }

    span {
      display: block;
    }

    &--xxl {
      color: white;
      text-transform: uppercase;
      line-height: 65%;
      font-size: 15cqw;
      width: 100%;
    }
  }

  @include sm {
    &__title--xl {
      font-size: 3.5rem;
      letter-spacing: 5px;
      text-align: right;

      span:first-of-type {
        transform: translateX(-20px);
      }

      span:last-of-type {
        text-align: left;
        transform: translateX(-10px);
      }
    }
  }

  @include md {
    &__title--xl {
      font-size: 4rem;

      span:first-of-type {
        transform: translateX(-40px);
      }

      span:last-of-type {
        transform: translateX(-20px);
      }
    }
  }

  @include lg {
    &__title--xl {
      font-size: 4.5rem;
    }

    span:first-of-type {
      transform: translateX(-120px);
    }
  }

  @include xl {
    &__title--xl {
      font-size: 5rem;
    }
  }
}

