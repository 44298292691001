.navbar-mobile {
  background-color: $emColor1;
  font-family: $defaultFontFamily;
  font-size: 1.25rem;

  @apply transition-all duration-500 -right-full fixed inset-y-0 z-10 w-full overflow-y-auto px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10 font-light;

  &__header {
    @apply flex items-center justify-between mt-4;

    &__toggler {
      @apply -m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white hover:text-white;
    }
  }

  &__menu {
    @apply mt-8 mb-2 space-y-4;

    &__item {
      @apply text-white uppercase font-light hover:text-white block;
    }
  }

  &.active {
    @apply right-0;
  }

}
