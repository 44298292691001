.color {
    &--1 {
        color: $emColor1 !important;
    }

    &--2 {
        color: $emColor2 !important;
    }

    &--3 {
        color: $emColor3 !important;
    }

    &--w {
        color: $wColor !important
    }
}

.color-bg{
    &--1 {
        background-color: $emColor1 !important;
    }

    &--2 {
      background-color: $emColor2 !important;
    }

    &--w {
      background-color: $wColor !important;
    }

    // Responsives classes must be after to allow CSS priority
    &--md-d-1 {
        @include md {
            background-color: $darkColor1 !important;
        }
    }

    &--lg-d-1 {
        @include lg {
            background-color: $darkColor1 !important;
        }
    }
}
