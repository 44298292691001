.background {

  &--dots {
    background: url("../img/symbol_orange.svg") repeat-y;
  }

  &--loader {
    background: url("../img/symbol_loader_purple.svg");
    background-repeat: no-repeat;
  }

  &--last-article {
    background: url("../img/symbol_purple.svg");
    background-repeat: no-repeat;
  }

  &--absolute-right {
    right: -200px;
    width: 300px;

    @apply absolute top-0 bottom-0;

    @include md {
      right: -100px;
    }

    @include lg {
      right: 10px;
    }
  }

  &--absolute-left {
    margin-left: -300px;
    width: calc(100% + 300px);

    @apply absolute top-0 bottom-0;
  }

  &--left {
    background-position: 50%;

    @include md {
      background-position: 90%;
      background-size: 35%;
    }
  }
}
