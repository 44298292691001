.opacity-filter-container {
    position: relative;
    z-index: 0;
}

div.opacity-filter,
div.opacity-filter--hover {
    height: 100%;
    left:0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;

    @include defaultTransition();
}

div.opacity-filter--black-30 { background-color: rgba(0,0,0,.3); }

div.opacity-filter--black-40 { background-color: rgba(0,0,0,.4); }

div.opacity-filter--black-50 { background-color: rgba(0,0,0,.5); }

div.opacity-filter--black-60 { background-color: rgba(0,0,0,.6); }

div.opacity-filter--black-70 { background-color: rgba(0,0,0,.7); }

div.opacity-filter--black-80 { background-color: rgba(0,0,0,.8); }
