.article-summary {
  background-color: #EDEDED;
  padding: $defaultPaddingY $mediumPaddingX;
  height: fit-content;

  &--margin-bottom {
    margin-bottom: $defaultMarginY;
    margin-top: 0;
  }

  @apply rounded-3xl break-inside-avoid;

  &__title {
    font-size: 1.25rem;
    @apply uppercase pb-2 font-semibold cursor-pointer;
  }

  &__date {
    font-size: .95rem;
    font-style: italic;
    @apply font-light;
  }

  &__categories {
    @apply pt-3;

    a {
      margin-right: $smallMarginX;
      @apply inline-block;
    }
  }

  &__summary {
    @apply py-3;
  }

  &__link {
    @apply flex justify-end;
  }
}

/* purgecss start ignore */
.article-content {
  ul,
  h2,
  h3 {
    margin-bottom: 0;
    margin-top: 0;
  }

  ul {
    list-style: disc;
    padding-bottom: $smallPaddingY;
    padding-top: $smallPaddingY;
    padding-inline-start: 40px;
  }

  h2 {
    color: $emColor2;
    font-size: 1.5625rem;
    padding-bottom: $smallPaddingY;
    padding-top: $defaultPaddingY;

    @apply uppercase font-semibold;
  }

  h3 {
    color: $emColor2;
    font-size: 1.25rem;
    padding-top: $smallPaddingY;

    @apply font-semibold;
  }

  h4 {
    color: $emColor1;
    font-size: 1.15rem;
    @apply font-semibold;
  }

  img {
    margin: $defaultMarginY auto;
    width: 60%;
  }

  p {
    margin-bottom: $smallMarginY;
  }

  strong {
    @apply font-semibold;
  }

  code {
    background-color: $lightColor2;
    font-size: 0.85rem;
    color: $emColor4;
    padding: 2px;
    word-break: break-word;
  }

  pre {
    background-color: $lightColor2;
    font-size: 0.85rem;
    overflow-x: scroll;
    padding: $smallPaddingY $defaultPaddingX;
  }

  a {
    @apply underline;
  }
}
/* purgecss end ignore */
