.form-group {
  @apply my-2 text-white;

  input, textarea {
    @apply bg-transparent border-b-2 border-white inline w-full;

    &[type=checkbox] {
      margin-right: $smallMarginX;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      background-color: transparent;
      border-radius: 50%;
      vertical-align: middle;
      border: 1px solid white;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      outline: none;
      cursor: pointer;

      &:checked {
        background-color: $emColor1;
        border: 1px solid $emColor1;
      }
    }
  }
}
